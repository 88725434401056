
import { Action } from "vuex-class";
import { Component, Vue, Prop } from "vue-property-decorator";
import { PackageSignPreview } from "@/store/modules/package.store";

@Component({
    components: {
        Spinner: () => import("@/components/general/spinner.vue"),
    },
})
export default class ConnectivePackagePreview extends Vue {
    @Action("package/requestPreview") requestPreview!: (packageId: number) => PackageSignPreview;

    @Prop({ required: true }) packageId!: number;

    loading = false;

    async handleRequestPreview() {
        this.loading = true;

        const data = await this.requestPreview(this.packageId);

        window.open(data.url, "_blank");

        this.loading = false;
    }
}
